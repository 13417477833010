html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*, *:before, *:after {
    box-sizing: inherit;
    font-family: Roboto, Arial, sans-serif;
}
body {
    margin: 0;
}
p {
    font-family: serif;
}

.version-info {
    font-size: 8pt;
    float: right;
    margin: 8px;
}
